import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div className='bg-gray-200' id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16 mt-96 pt-28 md:pt-24'>
                <div className='block md:flex py-5 gap-x-20 items-center mt-12'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <p className='mt-5 font-mono text-lg tracking-wide'>Kovaicalltaxi - Your Trusted Taxi Service in Coimbatore</p>
                        <p className='text-black opacity-60 md:text-lg mt-3'>Welcome to Kovaicalltaxi, your premier local taxi provider in Coimbatore. We are dedicated to offering reliable and affordable transportation solutions for both locals and visitors. Whether you need a quick ride around the city or a scenic journey to Ooty, we have the perfect package for you.</p>
                        <div className=' mt-5'>
                            <a
                                href="tel:+919789487007"
                                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
                            >
                                <span className=" flex gap-x-2 items-center justify-center">
                                    <FaPhone size={17} /> +91 9789487007
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About