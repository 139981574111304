import React from "react";
import sedan from "../../assets/images/cars/sedan.png";
import etios from "../../assets/images/cars/etios.png";
import suv from "../../assets/images/cars/suv.png";
import hatch from "../../assets/cars/hatchBank.png";
import maxicab from "../../assets/cars/hatchBank.png";
import PricePlanComponents from "./PricePlanComponents";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PricePlan = () => {

  return (
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16" id='tarrif'>
     <h4 className=" text-lg font-medium primary-color text-center">
    Our Tarrif
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center gap-2 md:mt-12">
        <div className="w-64 h-64 rounded-full primary-bg flex  flex-col items-center">
          <div className="flex items-center justify-center mt-12"> <img src={suv} width={190} height={200} /> </div>
          <p className="text-center text-3xl font-bold text-white mt-5">SUV</p>
        </div>
        <div className="w-64 h-64 rounded-full primary-bg flex  flex-col items-center">
          <div className="flex items-center justify-center mt-12"> <img src={sedan} width={210} height={200} /> </div>
          <p className="text-center text-3xl font-bold text-white mt-5">SEDAN</p>

        </div>
        <div className="w-64 h-64 rounded-full primary-bg flex  flex-col items-center">
          <div className="flex items-center justify-center mt-12"> <img src={maxicab} width={190} height={0} /> </div>
          <p className="text-center text-3xl font-bold text-white mt-5">Maxi Cab</p>
        </div>
        <div className="w-64 h-64 rounded-full primary-bg flex  flex-col items-center">
          <div className="flex items-center justify-center mt-12"> <img src={hatch} width={190} height={0} /> </div>
          <p className="text-center text-3xl font-bold text-white mt-5">Hatch Bank</p>
        </div>
      </div>
    </div>
  );
};

export default PricePlan;
