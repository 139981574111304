import React from 'react'
import { Link } from 'react-router-dom'

const OneWayServicesComponent = ({icon,title,para}) => {
  return (
<div className="relative cursor-pointer flex items-center grow basis-full flex-col text-center bg-white opacity-80  px-16 pt-20 rounded-full transition-all 2s ease-in hover:-translate-y-6">
                    <h2 className="text-xl font-bold mb-3 ">{title}</h2>
                    <p  className='mb-8'>{para}</p>
                    <div className="absolute left-[35%] md:left-[38%] -top-[12%] md:-top-[23%] outline animate-bounce outline-gray-200 bg-[#4d4d4d;] outline-[18px] h-24 w-24  rounded-full flex justify-center items-center">{icon}</div>
           </div>
  )
}

export default OneWayServicesComponent
