import React from 'react'
import banner from "../../assets/form_banner.jpg";
import { MdSafetyCheck } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";
import { MdStarRate } from "react-icons/md";
import { RiServiceFill } from "react-icons/ri";
import ser1 from '../../assets/ser1.jpg';
import ser2 from '../../assets/price.png';
import ser3 from '../../assets/ser3.jpg';
import ser4 from '../../assets/ser4.jpg';
const ChooseUs = () => {
    const divStyle = {
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
    };
    return (
        <div className='container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16'>
               <h4 className=" text-lg font-medium primary-color text-center">
      Why Choose Us
      </h4>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5'>
                <div className='bg-black rounded-3xl px-4 py-5 opacity-80 transition-all 2s ease-in hover:-translate-y-6 '>
                    <div className='flex justify-center'> <img src={ser1} className='rounded-3xl h-52 w-64' /> </div>
                    <p className='text-white text-2xl text-center mt-4'>Reliable Service</p>
                </div>
                <div className='bg-black rounded-3xl px-4 py-5 opacity-80 transition-all 2s ease-in hover:-translate-y-6'>
                    <div className='flex justify-center'> <img src={ser2} className='rounded-3xl h-40 h-52 w-64' /> </div>
                    <p className='text-white text-2xl text-center mt-4'>Affordable Rates</p>
                </div>
                <div className='bg-black rounded-3xl px-4 py-5 opacity-80 transition-all 2s ease-in hover:-translate-y-6'>
                    <div className='flex justify-center'> <img src={ser3} className='rounded-3xl h-40 h-52 w-64' /> </div>
                    <p className='text-white text-2xl text-center mt-4'>Experienced Drivers</p>
                </div>
                <div className='bg-black rounded-3xl px-4 py-5 opacity-80 transition-all 2s ease-in hover:-translate-y-6'>
                    <div className='flex justify-center'> <img src={ser4} className='rounded-3xl h-40 h-52 w-64' /> </div>
                    <p className='text-white text-2xl text-center mt-4'>Easy Booking</p>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs