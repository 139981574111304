import React from "react";
import banner from "../assets/images/carbanner.jpg";
import Form from "./Form";


const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "   top ",
    width: "100%",
    backgroundRepeat:'no-repeat',
    height:"12rem",
    borderRadius:'0px 0px 30px 30px',
  };

  return (
    <div className="container md:px-12 ">
    <div className="form_bg relative bg-fixed  px-3" style={divStyle}>
      <div className="flex justify-center ">
    <div className='bg-[#f2f2f2] w-full md:w-[80%] lg:w-1/2 rounded-xl md:rounded-lg px-4  pb-4 relative z-10 mt-16 md:mt-8 mb-4 md:mb-8  shadow-3xl' id='book_taxi_form'>
      <Form />
    </div>
  </div>
  </div>
  </div>   
  );
};

export default BookingForm;
