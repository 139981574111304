import React from "react";
import bg from "../../assets/images/servbg.png";
import chennai from "../../assets/images/services/chennai.jpg";
import coimbatore from "../../assets/images/services/coimbatore.jpg";
import bangalore from "../../assets/images/services/bangalore.jpg";
import madurai from "../../assets/images/services/madurai.jpeg";
import pondicherry from "../../assets/images/services/pondi.jpg";
import trichy from "../../assets/images/services/trichy.jpeg";
import vellore from "../../assets/images/services/vellore.jpg";
import kumbakonam from "../../assets/images/services/kumbakonam.webp";
import salem from "../../assets/images/services/salem.jpg";
import OneWayServicesComponent from "./OneWayServicesComponent";
import { BiTrip } from "react-icons/bi";
import { FaCarOn } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa";
const OneWayServices = () => {
  return (
    <div class='bg-gray-200'id='service'>
    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16">
      <h4 className=" text-lg font-medium primary-color text-center">
        Our Services
      </h4>
      <div className="grid grid-cols-1 lg:grid-cols-3 px-5 lg:px-16 mt-28 gap-24 lg:gap-5">
           <OneWayServicesComponent title='Local Taxi Services'  para= 'Explore Coimbatore with ease. Our local taxi services are designed to cater to your everyday transportation needs, providing you with comfortable and efficient rides throughout the city.' icon={<FaCarOn size={50} class='text-white'/>}/>
           <OneWayServicesComponent title='Outstation Packages'  para= 'Planning a trip to Ooty? Our outstation packages offer a convenient way to enjoy the beautiful hills without the hassle of driving. Choose from our various options tailored to fit your schedule and budget.' icon={<BiTrip size={50} class='text-white'/>}/>
           <OneWayServicesComponent title='Hourly Packages'  para= 'For those who need flexibility, our hourly packages allow you to book a taxi for a set number of hours. This is perfect for sightseeing, meetings, or any occasion where you need a ride on demand.' icon={<FaBusinessTime size={50} class='text-white'/>}/>
 
      </div>
    </div>
    </div>
  );
};

export default OneWayServices;
