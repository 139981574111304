import React from "react";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import bg from "../assets/images/servbg.png";
import kodaikanal from "../assets/images/kodaikanal.jpg";
import ooty from "../assets/images/ooty.jpg"
import kerala from "../assets/images/kerala.jpg"

const PopularOutstations = () => {
  return (
    <div className="bg-[#000]" style={{ backgroundImage: `url(${bg})` }}>
      <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8 ">
        <p className="text-white text-4xl text-center font-mono">TOUR PACKAGES</p>
        <div class='flex justify-center mt-16'>
          <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5" >
            <div className=' rounded-xl shadow-xl overflow-hidden relative card cursor-pointer'>
              <img src={kodaikanal} className='w-full h-[500px] rounded-3xl  ' />
              <div className="card_data w-4/5 px-16 py-8 mb-4">
              <span className="flex justify-center font-serif text-3xl text-[#4d4d4d] mt-2 ">Package </span>
              <div className="flex justify-center font-serif mt-2  ">Kodaikanal</div>
              </div>
            </div>
            <div className=' rounded-xl shadow-xl overflow-hidden relative card cursor-pointer'>
              <img src={ooty} className='w-full h-[500px] rounded-3xl  ' />
              <div className="card_data w-4/5 px-16 py-8 mb-4">
                <span className="flex justify-center font-serif text-3xl text-[#4d4d4d] mt-2 ">Package </span>
                <div className="flex justify-center font-serif mt-2  ">Ooty</div>
              </div>
            </div>           
            <div className=' rounded-xl shadow-xl overflow-hidden relative card cursor-pointer'>
              <img src={kerala} className='w-full h-[500px] rounded-3xl  ' />
              <div className="card_data w-4/5 px-16 py-8 mb-4">
              <span className="flex justify-center font-serif text-3xl text-[#4d4d4d] mt-2 ">Package </span>
              <div className="flex justify-center font-serif mt-2  ">Kerala</div>
              </div>
            </div>         
             </div>
        </div>
      </div>
      {/* <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8">
        <div className=" text-center">
          <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
            Kovai Call Taxi OUTSTATION CAB SERVICES
          </h3>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              CHENNAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
               Chennai to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Chennai to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              COIMBATORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Coimbatore to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              BANGALORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Bangalore to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            SALEM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Salem to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Salem to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Salem to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            MADURAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                 Madurai to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Madurai to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            PONDICHERRY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Pondicherry to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Pondicherry to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Pondicherry to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
      Pondicherry to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
          Pondicherry to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
          Pondicherry to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Pondicherry to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Pondicherry to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            VELLORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Vellore to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Vellore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
            Vellore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Vellore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Vellore to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Vellore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
      Vellore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Vellore to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            TRICHY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Trichy to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Trichy to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
            Trichy to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                richy to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Trichy to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Trichy to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Trichy to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Trichy to Kumbakonam Drop Taxi
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            KUMBAKONAM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Kumbakonam to ChennaKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
              Kumbakonam to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
            Kumbakonam to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
    Kumbakonam to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Kumbakonam to MaduraKovai Call Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                Kumbakonam to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
        Kumbakonam to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
      Kumbakonam to Trichy Drop Taxi
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default PopularOutstations;
